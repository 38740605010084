export default {
  // default breakpoint used in dualNav
  sm: '(width < 1368px)',
  // Cash-flow/Forecast breakpoints
  forecastXs: '(width < 1530px)',
  forecastSm: '(min-width: 1530px) and (max-width: 1815px)',
  forecastMd: '(min-width: 1815px) and (max-width: 2115px)',
  forecastLg: '(min-width: 2115px) and (max-width: 2415px)',
  forecastXl: '(width > 2415px)',
};
